import React from "react"
import { Row, Col } from "reactstrap"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div className="app-footer">
      <div className="app-container">
        <Row>
          <Col sm={12} md={8} className="text-center text-md-left">
            <Link className="px-2" to="/privacy">
              Privacy Policy
            </Link>
            <Link className="px-2" to="/terms">
              Terms of Use
            </Link>
          </Col>
          <Col sm={12} md={4} className="text-center text-md-right">
            © Copyright Creditminds, LLC. 2020
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Footer
