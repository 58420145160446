import React from "react"
import { Link } from "gatsby"

import logo from "../assets/logo.svg"

const Header = ({ onToggleMenu }) => {
  return (
    <header className="app-header">
      <Link to="/" className="mx-auto">
        <img src={logo} alt="Creditminds" height={24} className="app-header-logo" />
      </Link>
    </header>
  )
}

export default Header
